@import url("https://fonts.googleapis.com/css2?family=Be+Vietnam:wght@300;400;700&family=Philosopher:wght@400;700&family=Staatliches&display=swap");

@font-face {
  font-family: "Freigeist-WideRegular";
  src: url("fonts/Freigeist-WideRegular.eot");
  src: url("fonts/Freigeist-WideRegular.eot?#iefix") format("embedded-opentype"),
    url("fonts/Freigeist-WideRegular.woff2") format("woff2"),
    url("fonts/Freigeist-WideRegular.woff") format("woff"),
    url("fonts/Freigeist-WideRegular.ttf") format("truetype"),
    url("fonts/Freigeist-WideRegular.svg#Freigeist-WideRegular") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

* {
  margin: 0;
  padding: 0;
}
*,
::after,
::before {
  box-sizing: border-box;
}
body {
  margin: 0;
  font-family: "Be Vietnam", sans-serif;
  font-size: 16px;
}
.paragraph {
  text-transform: uppercase;
  font-size: 14px !important;
  line-height: 16px !important;
  display: inline-block;
  font-family: "Be Vietnam", sans-serif !important;
  letter-spacing: 0 !important;
}

.menu-appbar {
  position: fixed;
  right: 0;
  top: 0;
  background-color: #fff;
  bottom: 0;
  z-index: 11;
  width: 200px;
  flex-direction: column;
  display: flex;
  padding-top: 40px;
  padding-right: 20px;
  padding-left: 20px;
}
.space-root {
  position: relative;
}
.do-not-cross {
  position: fixed;
  right: 0;
  top: 0;
  z-index: 1111;
  width: 10vw;
}

img {
  max-width: 100%;
}
.react-draggable-dragged {
  z-index: 99999;
}
.space-content {
  font-size: 13px;
  text-transform: uppercase;
  text-align: center;
  padding: 22px 10px 0;
  transition: all linear 0.2s;
  line-height: 16px;
  font-family: "Be Vietnam", sans-serif;
}
.space-slider .p-3.wow.fadeInDown img {
  pointer-events: none;
}
.fadeInDown .space-slider {
  position: relative;
}
.space-slider .slick-slide {
  transition: all linear 0.2s;
}

.space-slider .p-3 {
  margin: 0 13px;
  transition: all linear 0.2s;
}
.space-slider .slick-slide img {
  width: 100%;
  cursor: pointer;
  transition: all linear 0.2s;
}

.space-slider .slick-slide:hover img {
  transform: scale(1.03);
}
.space-slider .slick-slide:not(.slick-center) {
  padding-top: 20px;
  transition: all linear 0.2s;
}
.space-slider .slick-slide:not(.slick-center) .p-3 {
  padding-top: 20px;
}
.space-slider .slick-slide:not(.slick-center) .space-content {
  opacity: 0;
  visibility: hidden;
}

.blank-button {
  background-color: transparent;
  border: 0;
  cursor: pointer;
  outline: none !important;
  transition: all linear 0.2s;
}
.bt-next {
  margin-right: 0px;
}
.bt-next:hover {
  margin-right: 0px;
}
.carobj {
  padding-bottom: 30px;
}
.bt-prev {
  margin-left: 0px;
}
.bt-prev:hover {
  margin-left: 0px;
}

.blank-button img {
  width: 70px;
  height: 70px;
  min-width: 70px;
}
.slider-arrow {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  margin-top: -110px;
}

.slide-btn {
  position: relative !important;
  top: -50px;
  left: 25px;
  z-index: 1;
  display: inline-flex;
}
.number-count {
  font-size: 18px;
  font-family: "Philosopher", sans-serif;
  margin-top: 20px;
  margin-left: 10px;
  letter-spacing: 2px;
}

.main-heading {
  font-size: 90px;
  text-transform: uppercase;
  font-family: "Staatliches", cursive;
  margin: 35px 0;
  font-weight: 400;
  letter-spacing: -1px;
}
.featured-slider {
  background-color: #000;
  padding: 12px 0;
  text-align: center;
}

.text-center {
  text-align: center;
}

.py-70 {
  padding-top: 55px;
  padding-bottom: 55px;
}

.object-content {
  text-align: center;
}

.object-content img {
  transition: all linear 0.2s;
  cursor: pointer;
}
.object-content:hover img {
  transform: scale(1.03);
}
.MuiPaper-root:before {
  background-color: #ffffff !important;
}
.object-content p {
  font-size: 14px;
  color: #010101;
  font-family: "Philosopher", sans-serif;
  text-transform: uppercase;
  margin-bottom: 0;
}
.object-content h6 {
  font-size: 14px;
  color: #010101;
  font-weight: 600;
  margin-top: 10px;
  letter-spacing: 2.8px;
}
.itemlink a {
  color: #140cf7;
}
.object-section .slick-slide img {
  margin: auto;
}
.logo-section {
  width: 140px;
  margin-top: 5px;
}
.contactPage .logo-section {
  margin-top: 25px;
}
.barder-top {
  margin-top: -195px;
}

.heading-minus-top {
  margin-top: -50px;
}

.barber-content h4 {
  font-family: "Philosopher", sans-serif;
  font-weight: 400;
  text-transform: uppercase;
  margin-bottom: 10px;
  font-size: 30px;
}
.barber-content {
  margin-bottom: 100px;
}
.barber-content p {
  font-size: 12px;
  text-transform: uppercase;
  margin-bottom: 0;
  margin-top: 0px;
}
.makeStyles-objectItem-5 {
  padding-top: 28px !important;
}
.barber-slider {
  padding-top: 15px;
  padding-bottom: 15px;
}

.barber-slider .slick-slide img {
  text-align: center;
  margin: auto;
}

.barber-slider ul.slick-dots {
  position: absolute;
  right: 0;
  top: 0;
  list-style: none;
}
.barber-slider ul.slick-dots button {
  border: 0;
  background-color: transparent;
  font-family: "Philosopher", sans-serif;
  margin-bottom: 25px;
  width: 30px;
  height: 25px;
  cursor: pointer;
}
.barber-slider ul.slick-dots .slick-active button {
  font-weight: bold;
  color: #1a1ff7;
}

.barber-slider .slick-slider {
  padding-right: 30px;
}

.dot-blink {
  width: 22px;
  height: 22px;
  background-color: #1a1ff7;
  display: inline-block;
  border-radius: 50%;
  margin-right: 7px;
  -webkit-animation: blink 1.7s infinite;
  -moz-animation: blink 1.7s infinite;
  -o-animation: blink 1.7s infinite;
  animation: blink 1.7s infinite;
  position: absolute;
  top: 20px;
  right: 20px;
}
@-webkit-keyframes blink {
  0%,
  100% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
}
@-moz-keyframes blink {
  0%,
  100% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
}
@-o-keyframes blink {
  0%,
  100% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
}
@keyframes blink {
  0%,
  100% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
}

.bottom-right {
  bottom: 120px;
  right: 100px;
  top: inherit;
}
.bottom-bottom {
  bottom: -40px;
  right: 70px;
  top: inherit;
}
.bottom-left {
  bottom: 110px;
  left: 20px;
  position: absolute;
  cursor: pointer;
  z-index: 20;
}
.menuSocialBtn {
  padding: 0 10px;
}
.menuSocialBtn img {
  max-width: 50px;
  width: 100%;
}
.menuSocialBtn .menuSocialBtnImg1 {
  display: none;
}
.menuSocialBtn:hover .menuSocialBtnImg1 {
  display: inline-block;
}
.menuSocialBtn:hover .menuSocialBtnImg {
  display: none;
}
.Instagram-Feed {
  margin-top: 15px;
}
.Instagram-Feed .slider-arrow {
  margin-top: 0;
}
.Instagram-Feed .Instagram-img {
  margin-left: 2px;
  margin-right: 2px;
}
.Instagram-img img {
  margin-bottom: 4px;
}

.white-arrow img {
  filter: brightness(1) invert(1);
}
.insta-bg {
  position: relative;
}

.player-wrapper {
  height: 100%;
}
.player-wrapper video {
  object-fit: cover;
}
.player-wrapper {
  height: calc(100vh - 36px);
}

.AboutSlider {
  max-width: 360px;
  margin-left: auto;
  margin-top: -200px;
  margin-right: 30px;
}
.AboutCarousel-arrow {
  bottom: 100px;
  left: 10px;
  position: absolute;
}

.numberCount {
  font-size: 18px;
  font-family: "Philosopher";
  margin-top: -45px;
  margin-left: 88px;
  letter-spacing: 2;
}
.sliderArrow {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  margin-top: 0;
  padding: 0;
  /* padding: 0px 140px 0px 140px; */
}

.ScrollDown {
  position: absolute;
  bottom: 80px;
  right: 40px;
  z-index: 1;
  width: 95px;
  animation: 1.5s infinite linear scrolldown;
}

.ScrollDownSpace {
  bottom: -30px;
  right: 180px;
  width: 126px;
}

@keyframes scrolldown {
  0% {
    transform: translateY(-10px);
  }
  50% {
    transform: translateY(10px);
  }
  100% {
    transform: translateY(-10px);
  }
}

.my-60 {
  margin-top: 60px;
  margin-bottom: 60px;
}
.h2-heading {
  color: #ffffff;
  font-size: 60px;
  font-family: "Staatliches";
  font-weight: 400;
  margin-bottom: 0px;
  line-height: 1.1;
}

.h2-heading1 {
  color: #000000;
  font-size: 50px;
  font-family: "Staatliches";
  font-weight: 400;
  margin-bottom: 0px;
  line-height: 1.1;
  text-align: center;
}

.h6-heading {
  font-family: "Staatliches";
  font-weight: 400;
  color: #fff;
  font-size: 23px;
  text-transform: uppercase;
}
.h6-heading1 {
  font-family: "Staatliches";
  font-weight: 400;
  color: #000;
  font-size: 23px;
  text-transform: uppercase;
  text-align: center;
}
.h6-heading1 span {
  font-weight: bold;
}

/** mediaQuery css **/
/** mediaQuery css **/
/** mediaQuery css **/
/** mediaQuery css **/

@media (max-width: 1279.95px) {
  .main-heading {
    font-size: 90px;
    margin: 50px 0;
  }
  .barder-top {
    margin-top: -140px;
  }
}
@media (max-width: 959.95px) {
  .blank-button img {
    width: 62px;
    height: 65px;
    min-width: 62px;
  }
  .main-heading {
    font-size: 70px;
    margin: 50px 0;
  }
  .barder-top {
    margin-top: -60px;
  }
  .barber-content h4 {
    font-size: 20px;
  }
  .barber-content {
    margin-bottom: 50px;
  }
}
@media (min-width: 599.96px) {
  .Dflex {
    display: flex;
    flex-direction: column;
  }
  .Instagram-Feed {
    order: 2;
  }
}
@media (max-width: 599.95px) {
  .slider-arrow {
    top: -60px;
    margin-top: 0;
  }
  .blank-button img {
    width: 39px;
    height: 39px;
    min-width: 39px;
    filter: brightness(1) invert(1);
  }
  .spaceCbtn img {
    filter: inherit;
  }
  .number-count {
    position: absolute;
    right: -14px;
    transform: rotate(90deg);
    bottom: 90px;
  }
  .space-slider {
    margin-top: 0px;
    margin-left: 20px;
    margin-right: 20px;
  }
  .space-slider .slick-slide:not(.slick-center) {
    padding-top: 0;
  }
  .space-slider .slick-slide:not(.slick-center) .p-3 {
    padding-top: 0;
  }
  .space-slider .p-3 {
    margin: 0;
  }
  .py-70 {
    padding-top: 20px;
    padding-bottom: 50px;
  }
  .barber-slider ul.slick-dots {
    display: none !important;
  }
  .main-heading {
    font-size: 45px;
    margin: 10px 0;
  }
  .barber-content h4 {
    display: none;
  }
  .barber-content {
    margin-bottom: 80px;
    position: absolute;
    transform: rotate(90deg);
    display: flex;
    width: 100%;
    bottom: 100%;
    top: 0;
  }
  .barber-slider .slick-slider {
    padding-right: 0;
  }
  .barber-slider .slick-list {
    overflow: visible;
  }

  .barder-top {
    margin-top: -50px;
  }
  .barber-content p {
    font-size: 10px;
    margin-right: 30px;
  }
  .barber-slider {
    padding-top: 0px;
    padding-bottom: 30px;
  }
  .do-not-cross {
    position: absolute;
    width: 20vw;
    z-index: -9999;
  }
  .space-root {
    padding-top: 40px;
  }
  .bottom-right {
    bottom: 80px;
    right: 20px;
  }
  .bottom-left {
    bottom: 80px;
  }
  .logo-section {
    width: 100px;
    margin-top: 0px;
  }
  .logo-section {
    margin-top: 14px;
  }
  .heading-minus-top {
    text-align: center;
  }
  .player-wrapper {
    height: 85vh;
  }
  .Instagram-img img {
    margin-bottom: 40px;
    margin-top: 40px;
    max-width: 60%;
  }
  .Instagram-img img ~ img {
    margin-left: auto;
  }
  .AboutSlider {
    max-width: 80vw;
    margin-left: auto;
    margin-top: -200px;
    margin-right: 0;
  }
  .sliderArrow {
    padding: 0px 0px 0px 0px;
  }
  .sliderArrow .blank-button img {
    filter: inherit;
  }
  .numberCount {
    margin-top: -35px;
    margin-left: 0;
    text-align: center;
  }
  .paragraph {
    font-size: 12px !important;
    line-height: 14px !important;
  }
  .my-60 {
    margin-top: 10px;
    margin-bottom: 30px;
  }
  .ScrollDownSpace {
    bottom: -30px;
    right: 30px;
    width: 76px;
  }
  .h2-heading {
    font-size: 40px;
  }
  .h2-heading1 {
    font-size: 35px;
  }
  .h6-heading {
    font-size: 19px;
  }
  .h6-heading1 {
    font-size: 14px !important;
  }
  .spaceimg-bn {
    height: 350px;
    object-fit: cover;
  }
}
